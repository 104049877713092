import React, { useEffect, useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { ArrowDown, Plus } from "../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";
import DebounceSearch from "../../commons/DebounceSearch";
import { Badge, SelectBox } from "../../components/micro";
import CustomTable from "../../commons/CustomTable";
import Action from "../../commons/Action";
import ConfirmationModel from "../../commons/ConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import { BroadcastsList, DeleteBroadcast } from "../../redux/broadcast/slice";
import { toast } from "react-toastify";

const Broadcast = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [selectedUserTypeStatus, setSelectedUserTypeStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [broadcastData, setBroadcastData] = useState({});
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selector
  const { broadcastsListData, loading } = useSelector((store) => ({
    broadcastsListData: store?.broadcastsData?.broadcastsListStatus?.data?.data,
    loading: store?.broadcastsData?.loading,
  }));

  useEffect(() => {
    dispatch(
      BroadcastsList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        sort_by: addSort,
        status: selectedStatus?.value ?? "",
        type: selectedUserTypeStatus?.value ?? "",
        sort_direction: addSortDirection === "asc" ? "asc" : "desc",
      })
    );
  }, [
    dispatch,
    searchValue,
    pageCount,
    pageLimit,
    addSort,
    addSortDirection,
    selectedStatus,
    selectedUserTypeStatus,
  ]);

  //   User Type Dropdown
  const userTypeDropdown = [
    { value: "3", label: "3 Year Subscription" },
    { value: "1", label: "1 Year Subscription" },
    { value: "5", label: "5 Year Subscription" },
  ];

  //   Status Dropdown
  const statusDropdown = [
    { value: "scheduled", label: "Scheduled" },
    { value: "sent", label: "Sent" },
  ];

  const handleDelete = () => {
    dispatch(DeleteBroadcast(broadcastData?.id)).then((res) => {
      if (res.type === "DeleteBroadcast/fulfilled") {
        toast.success(res?.payload?.message);
        dispatch(
          BroadcastsList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            sort_by: addSort,
            status: selectedStatus?.value ?? "",
            type: selectedUserTypeStatus?.value ?? "",
            sort_direction: addSortDirection === "asc" ? "asc" : "desc",
          })
        );
        setConfirmDelete(false);
      }
    });
  };
  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 table_responsive_text border-b font-semibold text-left  "
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left"
        >
          <div className="flex items-center">
            <p>Subject</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "subject"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("subject");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left"
        >
          <div className="flex items-center">
            <p>Content</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "content"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("content");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left"
        >
          <div className="flex items-center">
            <p>Types</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "types"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("subscription_type");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left"
        >
          <div className="flex items-center">
            <p>Send Date</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "send_date"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("notified_at");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left"
        >
          <div className="flex items-center">
            <p>Status</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "status"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("status");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left "
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(broadcastsListData?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate table_responsive_text">
              {(broadcastsListData?.current_page - 1) *
                broadcastsListData?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3 capitalize truncate table_responsive_text">
              {element?.subject}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              <p className="max-w-[100px] capitalize truncate">
                {element?.content}
              </p>
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`capitalize ${
                      element?.subscription_type === "1"
                        ? "border"
                        : element?.subscription_type === "5"
                        ? "border-success text-success"
                        : "border-theme text-theme"
                    }`}
                    value={`${
                      element?.subscription_type === "1"
                        ? "1 Year"
                        : element?.subscription_type === "5"
                        ? "5 Year"
                        : "3 Year"
                    } Subscription`}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.notified_at}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`text-white capitalize table_responsive_text ${
                      element?.status === "scheduled"
                        ? "border-success bg-success"
                        : "border-theme bg-theme"
                    }`}
                    value={element?.status}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3">
              <Action
                setConfirmDelete={setConfirmDelete}
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondaryLightShades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/create-edit-broadcast", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondaryLightShades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setBroadcastData(element);
                        setConfirmDelete(true);
                      }}
                    >
                      Delete
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex justify-between items-start">
          <div>
            <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
              Broadcast
            </h6>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbActive={"Broadcast"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <button
            className="btn-theme flex items-center uppercase"
            onClick={() => {
              navigate("/create-edit-broadcast");
            }}
          >
            <Plus className="mr-2" /> Add Broadcast
          </button>
        </div>
        <div className="sm:flex gap-2 lg:space-x-2 sm:space-y-0 space-y-2">
          <DebounceSearch
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search..."}
          />
          <div className="flex gap-3">
            <SelectBox
              className={"w-full max-w-xs"}
              paddingY={"2px"}
              id="userTypeDropdown"
              placeholder="User Type"
              options={userTypeDropdown}
              isLoading={false}
              getOptionValue={(selectedUserTypeStatus) =>
                selectedUserTypeStatus.value
              }
              value={selectedUserTypeStatus}
              onChange={(e) => {
                setSelectedUserTypeStatus(e);
              }}
            />
            <SelectBox
              className={"w-full max-w-lg"}
              paddingY={"2px"}
              id="status"
              placeholder="Select Status"
              options={statusDropdown}
              isLoading={false}
              getOptionValue={(selectedStatus) => selectedStatus.value}
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e);
              }}
            />
          </div>
        </div>
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={broadcastsListData}
            overflowVisibleLg={true}
            // loader
            loading={loading}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={broadcastsListData?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>

      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete"}
          isLoading={false}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default Broadcast;
