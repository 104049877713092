import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import PersonalDetails from "./PersonalDetails";
import AccountInformation from "./AccountInformation";
import Documents from "./Documents";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../../redux/customer/slice";
import { Provider } from "./CustomerContext";

const CreateEditCustomers = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const customerData = location?.state?.element;
  const [currentStep, setCurrentStep] = useState(0);
  const [createCustomerId, setCreateCustomerId] = useState();

  const { getCustomerData, loading } = useSelector((store) => ({
    getCustomerData: store?.customerData?.getCustomerStatus?.data?.data,
    loading: store?.customerData?.loading,
  }));

  const next = () => {
    if (currentStep < tabLabel.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // get customerData
  useEffect(() => {
    if (customerData?.id) {
      dispatch(getCustomer(customerData?.id));
    } else if (createCustomerId) {
      dispatch(getCustomer(createCustomerId));
    }
  }, [dispatch, customerData, createCustomerId]);

  const tabLabel = [
    { value: "personal_details", label: "Personal Details" },
    { value: "account_information", label: "Account Information" },
    { value: "documents", label: "Documents" },
  ];

  const renderStep = (step) => {
    switch (
      tabLabel[step]?.value // added optional chaining to prevent undefined access
    ) {
      case "personal_details":
        return (
          <PersonalDetails
            getCustomerData={
              customerData?.id || createCustomerId ? getCustomerData : ""
            }
            loading={loading}
            setCreateCustomerId={setCreateCustomerId}
          />
        );
      case "account_information":
        return (
          <AccountInformation
            customerData={customerData}
            getCustomerData={
              customerData?.id || createCustomerId ? getCustomerData : ""
            }
            isLoading={loading}
          />
        );
      case "documents":
        return (
          <Documents
            customerData={customerData}
            getCustomerData={
              customerData?.id || createCustomerId ? getCustomerData : ""
            }
            createCustomerId={createCustomerId}
            loading={loading}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="mb-5">
        <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
          Create Customers
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Create Customers"}
          breadCrumbParent2={"Customers"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>

      <Provider value={{ next, prev }}>
        <div className="w-full bg-white border rounded py-5 px-6">
          <div className={`flex sm:flex-nowrap flex-wrap mb-5`}>
            {tabLabel.map((tab, index) => (
              <div
                key={tab.label}
                className={`py-2 px-4 text-center uppercase border-b-2 transition duration-300 font-semibold sm:w-full w-auto responsive_base_text ${
                  index === currentStep
                    ? "border-theme text-theme"
                    : "border-transparent text-light_grey"
                }`}
              >
                {tab.label}
              </div>
            ))}
          </div>
          {renderStep(currentStep)}
        </div>
      </Provider>
    </>
  );
};

export default CreateEditCustomers;
