import React, { useEffect, useState } from "react";
// import { dashboardDetails } from "../../redux/dashboard/slice";
// import RippleLoader from "../../commons/RippleLoader";
import ReactSelect from "react-select";
import { ArrowUp } from "../../assets/svg/AllSvg";
import Cart from "../../commons/Cart";
import Tickets from "./Tickets";
import OnGoingTask from "./OnGoingTask";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTicketsList } from "../../redux/ticket/slice";
import RippleLoader from "../../commons/RippleLoader";

const Dashboard = () => {
  const [year, setYear] = useState({ value: "2013", label: "Year 2023" });
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  // selector
  const { ticketList, ticketListLoading } = useSelector((store) => ({
    ticketList: store?.ticketData?.getTicketsListStatus?.data?.data?.tickets,
    ticketListLoading: store?.ticketData?.loading,
  }));

  useEffect(() => {
    dispatch(
      getTicketsList({
        search: "",
        priority: "",
        period: "",
        pageCount: 1,
        pageLimit: 3,
        status: "",
      })
    );
  }, []);

  const card = [
    {
      customer: "Active Customer",
      textNumber: 5,
      up: "+ 15.6%",
      text: "From Previous Year",
    },
    {
      customer: "Active Customer",
      textNumber: 5,
      up: "+ 15.6%",
      text: "From Previous Year",
    },
  ];

  const series = [
    {
      name: "Last 12 Days",
      color: "#065699",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Last 12 Days",
      color: "#1795FF",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
  ];

  return (
    <>
      {/* {loadingPage && <RippleLoader />} */}
      <div className="w-full bg-white border rounded py-5 px-6">
        <h6 className="lg:text-lg text-base font-ebGaramond font-semibold sm:mb-5 mb-3">
          Welcome Back!
        </h6>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 mb-5">
          {card?.map((data, index) => {
            return (
              <div
                className="card text-theme bg-light_secondary_shades"
                key={index}
              >
                <p className="text-xs mb-2">{data?.customer}</p>
                <div className="flex justify-between">
                  <p className="lg:text-3xl sm:text-xl text-lg font-ebGaramond font-semibold">
                    {data?.textNumber}
                  </p>
                  <div className="text-xs text-right">
                    <p className="text-green-600 mb-1">{data?.up}</p>
                    <p>{data?.text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
          <div className="card lg:col-span-3 sm:col-span-2">
            <div className="flex justify-between mb-2">
              <p className="text-lg font-ebGaramond font-semibold mb-2">
                Revenue
              </p>
              <ReactSelect
                className="text-xs text-secondary remove-line"
                id="year"
                placeholder="Select Year"
                options={[
                  { value: "2013", label: "Year 2023" },
                  { value: "2022", label: "Year 2022" },
                  { value: "2021", label: "Year 2021" },
                  { value: "2020", label: "Year 2020" },
                ]}
                value={year}
                onChange={(e) => {
                  setYear(e);
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    border: `1px solid #e5e7eb`,
                    borderRadius: "0.25rem",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #065699",
                    },
                  }),
                }}
              />
            </div>
            <div className="flex justify-between">
              <p className="text-sm"> $ 7.852.000</p>
              <button className="text-theme border rounded border-theme uppercase text-xs px-2 py-1">
                View report
              </button>
            </div>
            <p className="flex items-center text-xs text-secondary mb-1">
              {" "}
              <span className="flex items-center text-green-600 mr-1">
                <ArrowUp className="mr-1" /> 2.1%
              </span>{" "}
              vs last week
            </p>
            <p className="text-sm opacity-65"> Sales from 1-12 Dec, 2020</p>
            <Cart series={series} />
          </div>
          <div className="card flex flex-col justify-between min-h-72">
            {ticketListLoading ? (
              <RippleLoader />
            ) : (
              <Tickets ticketList={ticketList} />
            )}
          </div>
          <div className="card">
            <OnGoingTask />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
