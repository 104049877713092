import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import { Form, Formik } from "formik";
import { InputWithLabel, TextareaWithLabel } from "../../../components/micro";
import * as Yup from "yup";
import { InputError } from "../../../commons/MicroComponents";
import Toggles from "../../../commons/Toggles";
import { useLocation, useNavigate } from "react-router-dom";
import OpenBankAccountForm from "./OpenBankAccountForm";
import ChangeShareholderParticulars from "./ChangeShareholderParticulars";
import AllotmentTransferShares from "./AllotmentTransferShares";
import NomineeDirector from "./NomineeDirector";
import { useDispatch, useSelector } from "react-redux";
import {
  getMasterSettingById,
  updateMasterSetting,
} from "../../../redux/masterSetting/slice";
import RippleLoader from "../../../commons/RippleLoader";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import PassAndOfficers from "./PassAndOfficers";

const EditSetting = () => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceData = location?.state?.element;
  // Selector
  const { masterSettingData, masterSettingDataLoading } = useSelector(
    (store) => ({
      // profile
      masterSettingData:
        store?.masterSettingData?.getMasterSettingByIdStatus?.data,
      masterSettingDataLoading: store?.masterSettingData?.loading,
    })
  );

  useEffect(() => {
    dispatch(getMasterSettingById(serviceData?.id));
  }, [dispatch, serviceData]);

  const handelSubmit = (value) => {
    const payload = {
      id: masterSettingData?.id,
      details: value,
      remarks: value?.remarks,
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        navigate("/service-settings");
      }
    });
  };
  return (
    <>
      <div className="mb-5">
        <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
          Service Setting
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbParent2={"Service Setting"}
          breadCrumbActive={serviceData?.name}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        {masterSettingDataLoading ? (
          <RippleLoader />
        ) : (
          <>
            <h1 className="font-ebGaramond lg:text-3xl sm:text-2xl text-xl mb-5">
              {serviceData?.service_name}
            </h1>
            {serviceData?.slug === "open_bank_account" ? (
              <OpenBankAccountForm masterSettingData={masterSettingData} />
            ) : serviceData?.slug === "change_of_shareholders_particulars" ? (
              <ChangeShareholderParticulars
                masterSettingData={masterSettingData}
              />
            ) : serviceData?.slug === "allotment_transfer_of_shares" ? (
              <AllotmentTransferShares masterSettingData={masterSettingData} />
            ) : serviceData?.slug === "nominee_director" ? (
              <NomineeDirector masterSettingData={masterSettingData} />
            ) : serviceData?.slug === "directors_officers_d_o" ? (
              <PassAndOfficers masterSettingData={masterSettingData} />
            ) : serviceData?.slug === "employment_pass" ? (
              <PassAndOfficers masterSettingData={masterSettingData} />
            ) : serviceData?.slug === "dependant_pass_loc" ? (
              <PassAndOfficers masterSettingData={masterSettingData} />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  price: masterSettingData?.detail?.price ?? "",
                  duration: masterSettingData?.durations ?? "",
                  request_free: masterSettingData?.detail?.free_request ?? "",
                  no_of_free_request:
                    masterSettingData?.detail?.no_of_free_request ?? "",
                  remarks: masterSettingData?.remarks ?? "",
                }}
                validationSchema={Yup.object().shape({
                  price: Yup.string().required("Please Enter Prices"),
                  duration: Yup.string().required("Please Enter Duration"),
                  remarks: Yup.string().required("Please Enter Remarks"),
                })}
                onSubmit={(values) => {
                  setValues(values);
                  setConfirmSave(true);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="grid sm:grid-cols-2 responsive_base_text gap-5">
                      {/* Prices */}
                      <InputWithLabel
                        className={
                          errors.price && touched.price
                            ? ` input-error `
                            : ` border `
                        }
                        disabled={!isEdit}
                        inputClass={!isEdit ? "text-gray-400" : ""}
                        idFromName="price"
                        label="Prices"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.price}
                        errors={errors.price && touched.price}
                        errorsText={errors.price}
                      />
                      {/* Duration (No. of Days) */}
                      <InputWithLabel
                        className={
                          errors.duration && touched.duration
                            ? ` input-error `
                            : ` border `
                        }
                        disabled={!isEdit}
                        inputClass={!isEdit ? "text-gray-400" : ""}
                        idFromName="duration"
                        label="Duration (No. of Days)"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duration}
                        errors={errors.duration && touched.duration}
                        errorsText={errors.duration}
                      />
                      {/* Provide Free Request (Check Box) */}
                      <div className="flex flex-col justify-center">
                        <div className="flex items-center space-x-3">
                          <Toggles
                            checked={values.request_free}
                            value={values.request_free}
                            toggleFor={"request_free"}
                            disabled={!isEdit}
                            onChange={(e) => {
                              setFieldValue("request_free", e.target.checked);
                            }}
                          />
                          <p className="text-light_grey">
                            Provide Free Request
                          </p>
                        </div>
                        {errors.request_free && (
                          <InputError errorTitle={errors.request_free} />
                        )}
                      </div>
                      {values.request_free && (
                        <div>
                          {/* No. of Request Free */}
                          <InputWithLabel
                            label={"No. of Request Free"}
                            type={"number"}
                            idFromName={"no_of_free_request"}
                            className={
                              errors.no_of_free_request &&
                              touched.no_of_free_request
                                ? ` input-error `
                                : ` border `
                            }
                            disabled={!isEdit}
                            inputClass={!isEdit ? "text-gray-400" : ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.no_of_free_request}
                            errors={
                              errors.no_of_free_request &&
                              touched.no_of_free_request
                            }
                            errorsText={errors.no_of_free_request}
                          />
                        </div>
                      )}
                      {/* Remarks */}
                      <div className="sm:col-span-2">
                        <TextareaWithLabel
                          className={
                            errors.remarks && touched.remarks
                              ? ` input-error `
                              : ` border `
                          }
                          disabled={!isEdit}
                          inputClass={!isEdit ? "text-gray-400" : ""}
                          idFromName="remarks"
                          label="Remarks"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.remarks}
                          errors={errors.remarks && touched.remarks}
                          errorsText={errors.remarks}
                        />
                      </div>
                      <div className="sm:col-span-2 flex justify-end gap-4 pt-3">
                        <button
                          type="button"
                          className="btn uppercase"
                          onClick={() => {
                            navigate("/service-settings");
                          }}
                        >
                          Cancel
                        </button>
                        {isEdit ? (
                          <button type="submit" className="btn-theme uppercase">
                            Save
                          </button>
                        ) : (
                          <div
                            className="btn-theme uppercase cursor-pointer"
                            onClick={() => {
                              setIsEdit(true);
                            }}
                          >
                            Edit
                          </div>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </>
        )}
      </div>
      {/* Confirmation Save */}
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </>
  );
};

export default EditSetting;
