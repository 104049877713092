import React, { useEffect, useState } from "react";
import { ArrowDown, Edit, Plus, Trash } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../commons/Breadcrumb";
import CustomTable from "../../commons/CustomTable";
import DebounceSearch from "../../commons/DebounceSearch";
import ViewPermissionList from "./ViewPermissionList";
import AddEditRole from "./AddEditRole";
import { useDispatch, useSelector } from "react-redux";
import { deleteRole, getRoleList } from "../../redux/accounts/slice";
import RippleLoader from "../../commons/RippleLoader";
import { toast } from "react-toastify";
import ConfirmationModel from "../../commons/ConfirmationModel";

const RoleAccess = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [viewPermissionListPopup, setViewPermissionListPopup] = useState(false);
  const [addEditRolePopup, setAddEditRolePopup] = useState(false);
  const [viewPermissionData, setViewPermissionData] = useState({});
  const [deleteRoleLoad, setDeleteRoleLoad] = useState(false);
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const dispatch = useDispatch();

  // Selector
  const { roleList, roleLoading } = useSelector((store) => ({
    // profile
    roleList: store?.accountsData?.getRoleListStatus?.data,
    roleLoading: store?.accountsData?.loading,
  }));

  useEffect(() => {
    dispatch(
      getRoleList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        sort_by: addSort,
        sort_direction: addSortDirection === "asc" ? "asc" : "desc",
      })
    );
  }, [dispatch, pageCount, pageLimit, searchValue, addSort, addSortDirection]);

  // Delete Role
  const handleDeleteRole = () => {
    setDeleteRoleLoad(true);
    dispatch(deleteRole(viewPermissionData?.id)).then((res) => {
      setDeleteRoleLoad(false);
      if (res.type === "deleteRole/fulfilled") {
        toast.success(res?.payload?.message);
        dispatch(
          getRoleList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            sort_by: addSort,
            sort_direction: addSortDirection === "asc" ? "asc" : "desc",
          })
        );
        setConfirmDelete(false);
      }
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 table_responsive_text border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Role Name</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" ? "rotate-180" : ""
              }`}
              onClick={() => {
                setAddSort("name");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Permission List
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light "
        >
          Actions
        </th>
      </>
    );
  };

  // Table Row
  const DataRows = () => {
    return (
      <>
        {(roleList?.data?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 table_responsive_text truncate">
              {(roleList?.data?.current_page - 1) * roleList?.data?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3 table_responsive_text truncate">
              {element?.title}
            </td>
            <td className="px-5 py-3 table_responsive_text truncate">
              <button
                className="text-theme border-b border-theme"
                type="button"
                onClick={() => {
                  setViewPermissionListPopup(true);
                  setViewPermissionData(element);
                }}
              >
                View
              </button>
            </td>
            <td className="px-4 py-2 table_responsive_text truncate">
              <div className="flex items-center space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setAddEditRolePopup(true);
                    setViewPermissionData(element);
                  }}
                >
                  <Edit />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setConfirmDelete(true);
                    setViewPermissionData(element);
                  }}
                >
                  <Trash />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="flex justify-between items-start mb-5">
        <div>
          <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
            Role & Access
          </h6>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Role & Access"}
            breadCrumbParent2={"Accounts"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <button
          className="btn-theme flex items-center"
          onClick={() => {
            setViewPermissionData({});
            setAddEditRolePopup(true);
          }}
        >
          <Plus className="mr-2" /> Add Role
        </button>
      </div>
      <div className="mb-5">
        <DebounceSearch
          search={searchValue}
          handleChange={setSearchValue}
          placeholder={"Search..."}
        />
      </div>
      <div className="w-full bg-white border rounded overflow-hidden">
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          overflowVisibleLg={true}
          data={roleList?.data}
          // loader
          loading={roleLoading}
          showPagination={true}
          // showLimit
          setPaginationCurrentLimit={setPageLimit}
          paginationCurrentLimit={pageLimit}
          // paginationData
          total={roleList?.data?.total}
          current={pageCount}
          paginationData={(crPage) => setPageCount(crPage)}
        />
      </div>

      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete"}
          isLoading={deleteRoleLoad}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={handleDeleteRole}
        />
      )}
      {viewPermissionListPopup && (
        <ViewPermissionList
          viewPermissionData={viewPermissionData}
          setViewPermissionListPopup={setViewPermissionListPopup}
          setAddEditRolePopup={setAddEditRolePopup}
        />
      )}
      {addEditRolePopup && (
        <AddEditRole
          viewPermissionData={viewPermissionData}
          setAddEditRolePopup={setAddEditRolePopup}
          searchValue={searchValue}
          addSortDirection={addSortDirection}
          addSort={addSort}
          pageCount={pageCount}
          pageLimit={pageLimit}
        />
      )}
    </>
  );
};

export default RoleAccess;
