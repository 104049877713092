import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import DebounceSearch from "../../../commons/DebounceSearch";
import { Badge, TextareaWithLabel } from "../../../components/micro";
import CustomTable from "../../../commons/CustomTable";
import Toggles from "../../../commons/Toggles";
import Action from "../../../commons/Action";
import { useDispatch, useSelector } from "react-redux";
import {
  getSetting,
  getSettingsType,
  updateSubscriptionRemarks,
  updateSubscriptionStatus,
} from "../../../redux/masterSetting/slice";
import { ArrowDown, Close } from "../../../assets/svg/AllSvg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";

const ServiceSetting = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedTypeStatus, setSelectedTypeStatus] = useState("");
  const [serviceSettingData, setServiceSettingData] = useState({});
  const [settingsTypeArray, setSettingsTypeArray] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const [showRemarkPopup, serShowRemarkPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selector
  const { serviceSettingList, serviceSettingLoading, settingsType } =
    useSelector((store) => ({
      // profile
      serviceSettingList: store?.masterSettingData?.getSettingStatus?.data,
      settingsType: store?.masterSettingData?.getSettingsTypeStatus?.data,
      serviceSettingLoading: store?.masterSettingData?.loading,
    }));

  useEffect(() => {
    dispatch(
      getSetting({
        settingType: "service_setting",
        sub_type:
          selectedTypeStatus.label === "Bank Account"
            ? "bank_account"
            : selectedTypeStatus.label,
        pageCount: pageCount,
        pageLimit: pageLimit,
        sort_by: addSort,
        sort_direction: addSortDirection === "asc" ? "asc" : "desc",
      })
    );
    dispatch(getSettingsType("service_setting"));
  }, [
    dispatch,
    pageCount,
    pageLimit,
    selectedTypeStatus,
    addSort,
    addSortDirection,
  ]);

  useEffect(() => {
    if (settingsType) {
      let data = settingsType?.map((x) => ({
        label: x === "bank_account" ? "Bank Account" : x,
      }));
      setSettingsTypeArray(data);
    }
  }, [settingsType]);

  // Update Status
  const updateStatus = (element) => {
    dispatch(
      updateSubscriptionStatus({
        id: element?.id,
        data: {
          status: element?.status === "inactive" ? "active" : "inactive",
        },
      })
    ).then((res) => {
      if (res.type === "updateSubscriptionStatus/fulfilled") {
        dispatch(
          getSetting({
            settingType: "service_setting",
            sub_type: "service_request",
            pageCount: pageCount,
            pageLimit: pageLimit,
            sort_by: addSort,
            sort_direction: addSortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 table_responsive_text border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Services </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "service_name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("service_name");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Durations </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "durations"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("durations");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Price </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "price"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("price");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Type </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "sub_type"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("sub_type");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Remarks
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(serviceSettingList?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">
              {(serviceSettingList?.current_page - 1) *
                serviceSettingList?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3 truncate">
              <p className="max-w-[10rem]">{element?.service_name}</p>
            </td>
            <td className="px-5 py-3 truncate">{element?.durations}</td>
            <td className="px-5 py-3 truncate">
              {element?.detail?.price ?? "-"}
            </td>
            <td className="px-5 py-3 truncate">
              <div className="flex justify-start">
                <Badge
                  className={`capitalize ${
                    element?.sub_type === "director"
                      ? "text-white border-success bg-success"
                      : element?.sub_type === "shareholder"
                      ? "text-white border-theme bg-theme"
                      : element?.sub_type === "company"
                      ? "text-white border-success bg-success"
                      : "border-light_action_selected bg-light_action_selected"
                  }`}
                  value={
                    element?.sub_type === "bank_account"
                      ? "Bank Account"
                      : element?.sub_type
                  }
                />
              </div>
            </td>
            <td className="px-5 py-3 truncate">
              <Toggles
                checked={element?.status === "inactive" ? false : true}
                toggleFor={element?.id}
                onChange={() => {
                  updateStatus(element);
                }}
              />
            </td>
            <td className="px-5 py-3 truncate">
              <button
                type="button"
                className="text-theme border-b border-theme"
                onClick={() => {
                  setServiceSettingData(element);
                  serShowRemarkPopup(true);
                }}
              >
                View
              </button>
            </td>
            <td className="flex justify-start px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/edit-service", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
          Service Setting
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Service Setting"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="sm:flex items-center sm:space-x-3 sm:space-y-0 space-y-2">
        <DebounceSearch
          search={searchValue}
          handleChange={setSearchValue}
          placeholder={"Search..."}
        />
        <ReactSelect
          className={
            "w-full sm:max-w-xs text-black responsive_base_text capitalize p-0"
          }
          id="settingsDropdown"
          placeholder="Select Type"
          options={settingsTypeArray}
          isLoading={false}
          getOptionValue={(selectedTypeStatus) => selectedTypeStatus.label}
          value={selectedTypeStatus}
          onChange={(e) => {
            setSelectedTypeStatus(e);
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
      </div>

      {/* Table */}
      <div className="w-full bg-white border rounded">
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={serviceSettingList}
          overflowVisibleLg={true}
          // loader
          loading={serviceSettingLoading}
          showPagination={true}
          // showLimit
          setPaginationCurrentLimit={setPageLimit}
          paginationCurrentLimit={pageLimit}
          // paginationData
          total={serviceSettingList?.total}
          current={pageCount}
          paginationData={(crPage) => setPageCount(crPage)}
        />
      </div>
      {/* Remark Popup */}
      {showRemarkPopup && (
        <div
          className="model"
          onClick={() => {
            serShowRemarkPopup(false);
          }}
        >
          <div
            className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-36 py-5 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="text-xl font-ebGaramond font-semibold w-full border-b pb-3 mb-4">
              {serviceSettingData?.service_name} Remarks
            </p>
            <Close
              className="cursor-pointer absolute top-5 right-5"
              onClick={() => {
                serShowRemarkPopup(false);
              }}
            />
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  remarks: serviceSettingData?.remarks ?? "",
                }}
                validationSchema={Yup.object().shape({
                  remarks: Yup.string().required("Please Enter Remarks"),
                })}
                onSubmit={(values) => {
                  dispatch(
                    updateSubscriptionRemarks({
                      id: serviceSettingData?.id,
                      data: values,
                    })
                  ).then((res) => {
                    serShowRemarkPopup(false);
                    if (res.type === "updateSubscriptionRemarks/fulfilled") {
                      dispatch(
                        getSetting({
                          settingType: "service_setting",
                          sub_type: "service_request",
                          pageCount: pageCount,
                          pageLimit: pageLimit,
                        })
                      );
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextareaWithLabel
                      className={
                        errors.remarks && touched.remarks
                          ? ` input-error `
                          : ` border `
                      }
                      idFromName="remarks"
                      label="Remarks"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.remarks}
                      errors={errors.remarks && touched.remarks}
                      errorsText={errors.remarks}
                    />
                    <div className="flex justify-end gap-4 pt-3">
                      <button type="submit" className="btn-theme uppercase">
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceSetting;
