import React from "react";
import { Icon } from "../../assets/svg/AllSvg";
import CustomTable from "../../commons/CustomTable";
import { Link } from "react-router-dom";
import AllGood from "../../commons/AllGood";

const Tickets = ({ ticketList }) => {
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-secondary_light_shades whitespace-nowrap "
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-secondary_light_shades"
        >
          Tickets
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-secondary_light_shades"
        >
          Actions
        </th>
      </>
    );
  };
  const DataRows = () => {
    return (
      <>
        {(ticketList?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text bg-light_secondary hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">
              {(ticketList?.current_page - 1) * ticketList?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3 truncate">{element?.title}</td>
            <td className="px-5 py-3 truncate">
              <Link
                to={"/tickets"}
                className="text-theme border-b border-theme"
              >
                View
              </Link>
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <div>
        <p className="lg:text-lg flex items-center font-ebGaramond font-semibold mb-2">
          <Icon className="fill-theme mr-2" width={24} /> Tickets
        </p>
        {ticketList?.data.length ? (
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={ticketList}
            overflowVisibleLg={false}
            // loader
            loading={false}
            showPagination={false}
          />
        ) : (
          <AllGood />
        )}
      </div>
      <div className="text-right sm:mt-5 mt-3">
        <Link
          to={"/tickets"}
          className="text-theme border-b border-theme sm:text-sm text-xs font-bold uppercase text-right w-full"
        >
          View All
        </Link>
      </div>
    </>
  );
};

export default Tickets;
