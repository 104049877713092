import React, { useEffect, useState } from "react";
import { Badge } from "../../../components/micro";
import EditOrganisationPopup from "./EditOrganisationPopup";
import AddEditDirectors from "./components/AddEditDirectors";
import AddEditUser from "./components/AddEditUser";
import Action from "../../../commons/Action";
import { useDispatch } from "react-redux";
import RippleLoader from "../../../commons/RippleLoader";
import {
  DeleteDirectorShareholder,
  existingUsers,
} from "../../../redux/organisation/slice";
import { toast } from "react-toastify";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import { getCompanyById } from "../../../redux/companies/slice";

const Organisation = ({ companyByIdData, onLoading, companyId }) => {
  const dispatch = useDispatch();

  const [showEditOrganisationPopup, setShowEditOrganisationPopup] =
    useState(false);
  const [showDirectorsPopup, setShowDirectorsPopup] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [organisationData, setOrganisationData] = useState({});
  const [currentUserId, setCurrentUserId] = useState(null);
  const directorsData = [
    ...companyByIdData?.nominee_directors,
    ...companyByIdData?.resident_directors,
    ...companyByIdData?.foreign_directors,
  ];
  const [isDirectorsOpen, setIsDirectorsOpen] = useState(true);
  const [isIndividualShareholdersOpen, setIsIndividualShareholdersOpen] =
    useState(false);
  const [isCorporateShareholdersOpen, setIsCorporateShareholdersOpen] =
    useState(false);
  const [isUserOpen, setIsUserOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editType, setEditType] = useState("");
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const type = "director";

  useEffect(() => {
    dispatch(existingUsers(type));
  }, []);

  useEffect(() => {
    setLoading(true);

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setConfirmModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      await dispatch(DeleteDirectorShareholder(deleteId));
    } catch (error) {
      toast.error("Failed to delete folder.");
    } finally {
      setLoading(false);
      setConfirmModalOpen(false);
      // dispatch(existingUsers(type));
      dispatch(getCompanyById(companyByIdData?.id));
    }
  };

  return (
    <>
      {loading || onLoading ? (
        <div className="flex justify-center items-center h-full">
          <RippleLoader />
        </div>
      ) : (
        <>
          <div className="bg-light_secondary rounded-xl px-8 mb-5">
            {/* Directors Accordion */}
            <div className="border-b border-secondary_grey py-5">
              <div className="flex justify-between mb-3">
                <p className="text-theme text-xl font-ebGaramond">
                  Directors ({directorsData?.length})
                </p>
                <div className="flex">
                  <button
                    className="text-theme border-b border-theme mr-4"
                    onClick={() => {
                      setShowDirectorsPopup(true);
                      setCurrentUserId(null);
                      setEditType("Directors");
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="flex items-center text-theme"
                    onClick={() => setIsDirectorsOpen(!isDirectorsOpen)}
                  >
                    {isDirectorsOpen ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </div>
              {isDirectorsOpen && directorsData?.length ? (
                <div className="space-y-3">
                  {directorsData.map((element, index) => {
                    const isLastElement = directorsData.length - 1 === index;
                    return (
                      <div key={element.user.id}>
                        <div
                          className={`grid grid-cols-5 gap-5 ${
                            isLastElement ? "" : "border-b pb-3"
                          }`}
                        >
                          <div className="space-y-1">
                            <p className="text-xs font-semibold">Name</p>
                            <p>{element.user?.full_name}</p>
                            <p className="text-xs text-light_grey font-semibold">
                              {element.user?.phone}
                            </p>
                            <p className="text-xs text-light_grey font-semibold">
                              {element.user?.email}
                            </p>
                          </div>
                          <div className="space-y-1">
                            <p className="text-xs font-semibold">Role:</p>
                            <p className="text-xs text-light_grey font-semibold capitalize">
                              {element.user?.role.replace("_", " ")}
                            </p>
                          </div>
                          <div className="space-y-2">
                            <p className="text-xs font-semibold">
                              Identity Verification Status
                            </p>
                            <Badge
                              className={`${
                                element.status === "approved"
                                  ? "text-white border-success bg-success"
                                  : "text-black bg-secondary_light_shades"
                              } font-medium text-sm capitalize inline-block !py-1`}
                              value={element.status}
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="text-xs font-semibold">KYC Status</p>
                            <Badge
                              className={`capitalize font-medium inline-block text-sm !py-1 mb-3 ${
                                element?.user?.kyc_status === "low_risk"
                                  ? "border-green-700 text-green-700"
                                  : element?.user?.kyc_status === "mid_risk"
                                  ? "border-orange-700 text-orange-700"
                                  : element?.user?.kyc_status === "high_risk"
                                  ? "border-red-700 text-red-700"
                                  : element?.user?.kyc_status === "pending"
                                  ? "text-black bg-secondary_light_shades"
                                  : ""
                              }`}
                              value={
                                element?.user?.kyc_status === "low_risk"
                                  ? "Low Risk"
                                  : element?.user?.kyc_status === "mid_risk"
                                  ? "Mid Risk"
                                  : element?.user?.kyc_status === "high_risk"
                                  ? "High Risk"
                                  : element?.user?.kyc_status === "pending"
                                  ? "Pending"
                                  : "No Status"
                              }
                            />
                          </div>
                          <div className="flex items-center justify-end">
                            <Action
                              optionList={
                                <>
                                  <button
                                    className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                    onClick={() => {
                                      setShowDirectorsPopup(true);
                                      setCurrentUserId(element?.user?.id);
                                      setEditType("Directors");
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                    onClick={() =>
                                      handleDeleteClick(element?.id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Individual Shareholders Accordion */}
            <div className="border-b border-secondary_grey py-5">
              <div className="flex justify-between mb-3">
                <p className="text-theme text-xl font-ebGaramond">
                  Individual Shareholders (
                  {companyByIdData?.shareholders?.length})
                </p>
                <div className="flex">
                  <button
                    className="text-theme border-b border-theme mr-4"
                    onClick={() => {
                      setShowDirectorsPopup(true);
                      setCurrentUserId(null);
                      setEditType("IndividualShareholders");
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="flex items-center text-theme"
                    onClick={() =>
                      setIsIndividualShareholdersOpen(
                        !isIndividualShareholdersOpen
                      )
                    }
                  >
                    {isIndividualShareholdersOpen ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </div>
              {isIndividualShareholdersOpen &&
              companyByIdData?.shareholders?.length ? (
                <div className="space-y-3">
                  {companyByIdData?.shareholders?.map((element, index) => {
                    return (
                      <div
                        key={element.id}
                        className={`grid grid-cols-5 gap-5 ${
                          companyByIdData.shareholders.length - 1 === index
                            ? ""
                            : "border-b pb-3"
                        }`}
                      >
                        <div className="space-y-1">
                          <p className="text-xs font-semibold">Name</p>
                          <p>{element.user?.full_name}</p>
                          <p className="text-xs text-light_grey font-semibold">
                            {element.user?.phone}
                          </p>
                          <p className="text-xs text-light_grey font-semibold">
                            {element.user?.email}
                          </p>
                        </div>
                        <div className="space-y-1">
                          <p className="text-xs font-semibold">Role:</p>
                          <p className="text-xs text-light_grey font-semibold">
                            {element.user?.role.replace("_", " ")}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="text-xs font-semibold">
                            Identity Verification Status
                          </p>
                          <Badge
                            className={`${
                              element.status === "approved"
                                ? "text-white border-success bg-success"
                                : "text-black bg-secondary_light_shades"
                            } font-medium text-sm capitalize inline-block !py-1`}
                            value={element.status}
                          />
                        </div>
                        <div className="space-y-2">
                          <p className="text-xs font-semibold">KYC Status</p>
                          <Badge
                            className={`capitalize font-medium inline-block text-sm !py-1 mb-3 ${
                              element.user?.kyc_status === "low_risk"
                                ? "border-green-700 text-green-700"
                                : element.user?.kyc_status === "mid_risk"
                                ? "border-orange-700 text-orange-700"
                                : element.user?.kyc_status === "high_risk"
                                ? "border-red-700 text-red-700"
                                : element.user?.kyc_status === "pending"
                                ? "text-black bg-secondary_light_shades"
                                : ""
                            }`}
                            value={
                              element.user?.kyc_status === "low_risk"
                                ? "Low Risk"
                                : element.user?.kyc_status === "mid_risk"
                                ? "Mid Risk"
                                : element.user?.kyc_status === "high_risk"
                                ? "High Risk"
                                : element.user?.kyc_status === "pending"
                                ? "Pending"
                                : "No Status"
                            }
                          />
                        </div>
                        <div className="flex items-center justify-end">
                          <Action
                            optionList={
                              <>
                                <button
                                  className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  onClick={() => {
                                    setShowDirectorsPopup(true);
                                    setCurrentUserId(element.id);
                                    setEditType("IndividualShareholders");
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  onClick={() => handleDeleteClick(element.id)}
                                >
                                  Delete
                                </button>
                              </>
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Corporate Shareholders Accordion */}
            <div className="py-5">
              <div className="flex justify-between mb-3">
                <p className="text-theme text-xl font-ebGaramond">
                  Corporate Shareholders (
                  {companyByIdData?.shareholders?.filter(
                    (element) => element.shareholder_type === "corporate"
                  ).length || 0}
                  )
                </p>
                <div className="flex">
                  <button
                    className="text-theme border-b border-theme mr-4"
                    onClick={() => {
                      setShowDirectorsPopup(true);
                      setCurrentUserId(null);
                      setEditType("CorporateShareholders");
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="flex items-center text-theme"
                    onClick={() =>
                      setIsCorporateShareholdersOpen(
                        !isCorporateShareholdersOpen
                      )
                    }
                  >
                    {isCorporateShareholdersOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 15l7-7 7 7"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              {isCorporateShareholdersOpen &&
              companyByIdData?.shareholders?.length ? (
                <div className="space-y-3">
                  {companyByIdData?.shareholders
                    ?.filter(
                      (element) => element.shareholder_type === "corporate"
                    )
                    .map((element, index) => (
                      <div
                        className={`grid grid-cols-5 gap-5 ${
                          element.length - 1 === index ? "" : "border-b pb-3"
                        }`}
                        key={element.id}
                      >
                        <div className="space-y-1">
                          <p className="text-xs font-semibold">Company Name:</p>
                          <p>{element?.company_name}</p>
                          <p className="text-xs text-light_grey font-semibold">
                            {element?.user?.phone}
                          </p>
                          <p className="text-xs text-light_grey font-semibold">
                            {element?.user?.email}
                          </p>
                        </div>
                        <div className="space-y-1">
                          <p className="text-xs font-semibold">Role:</p>
                          <p className="text-xs text-light_grey font-semibold capitalize">
                            {element?.user?.role.replace("_", " ")}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="text-xs font-semibold">
                            Identity Verification Status
                          </p>
                          <Badge
                            className={`${
                              element?.status === "approved"
                                ? "text-white border-success bg-success"
                                : "text-black bg-secondary_light_shades"
                            } font-medium text-sm capitalize inline-block !py-1`}
                            value={element?.status}
                          />
                        </div>
                        <div className="space-y-2">
                          <p className="text-xs font-semibold">KYC Status</p>
                          <Badge
                            className={`capitalize font-medium inline-block text-sm !py-1 mb-3 ${
                              element?.user?.kyc_status === "low_risk"
                                ? "border-green-700 text-green-700"
                                : element?.user?.kyc_status === "mid_risk"
                                ? "border-orange-700 text-orange-700"
                                : element?.user?.kyc_status === "high_risk"
                                ? "border-red-700 text-red-700"
                                : element?.user?.kyc_status === "pending"
                                ? "text-black bg-secondary_light_shades"
                                : ""
                            }`}
                            value={
                              element?.user?.kyc_status === "low_risk"
                                ? "Low Risk"
                                : element?.user?.kyc_status === "mid_risk"
                                ? "Mid Risk"
                                : element?.user?.kyc_status === "high_risk"
                                ? "High Risk"
                                : element?.user?.kyc_status === "pending"
                                ? "Pending"
                                : "No Status"
                            }
                          />
                        </div>
                        <div className="flex items-center justify-end">
                          <Action
                            optionList={
                              <>
                                <button
                                  className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  onClick={() => {
                                    setShowDirectorsPopup(true);
                                    setCurrentUserId(element?.id);
                                    setEditType("CorporateShareholders");
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  onClick={() => handleDeleteClick(element?.id)}
                                >
                                  Delete
                                </button>
                              </>
                            }
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          </div>

          {/* User List */}
          <div className="bg-light_secondary_shades rounded-xl px-8">
            <div className="py-5">
              <div className="flex justify-between mb-3">
                <p className="text-theme text-xl font-ebGaramond">
                  Users ({companyByIdData?.system_users?.length || 0})
                </p>
                <div className="flex">
                  <button
                    className="text-theme border-b border-theme mr-4"
                    onClick={() => {
                      setShowUserPopup(true);
                      setCurrentUserId(null);
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="flex items-center text-theme"
                    onClick={() => setIsUserOpen(!isUserOpen)}
                  >
                    {isUserOpen ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 15l7-7 7 7"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </div>
              {isUserOpen ? (
                companyByIdData?.system_users?.length > 0 ? (
                  <div className="space-y-3">
                    {companyByIdData.system_users.map((element, index) => {
                      return (
                        <>
                          <div
                            className={`grid grid-cols-4 gap-5 ${
                              companyByIdData.system_users.length - 1 === index
                                ? ""
                                : "border-b pb-3"
                            }`}
                            key={element.id}
                          >
                            <div className="space-y-1">
                              <p className="text-xs font-semibold">Name:</p>
                              <p>{element?.user?.full_name}</p>
                              <p className="text-xs text-light_grey font-semibold">
                                {element?.user?.phone}
                              </p>
                              <p className="text-xs text-light_grey font-semibold">
                                {element?.user?.email}
                              </p>
                            </div>
                            <div className="space-y-1">
                              <p className="text-xs font-semibold">Role:</p>
                              <p className="text-xs text-light_grey font-semibold capitalize">
                                {element?.user?.role.replace("_", " ")}
                              </p>
                            </div>
                            <div className="space-y-2">
                              <p className="text-xs font-semibold">Status</p>
                              <Badge
                                className={`${
                                  element?.status === "active"
                                    ? "text-white border-success bg-success"
                                    : "text-black bg-secondary_light_shades"
                                } font-medium text-sm capitalize inline-block !py-1`}
                                value={element?.status}
                              />
                            </div>
                            <div className="flex items-center justify-end">
                              <Action
                                optionList={
                                  <>
                                    <button
                                      className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                      onClick={() => {
                                        setShowUserPopup(true);
                                        setCurrentUserId(element);
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="flex w-full gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                      onClick={() =>
                                        handleDeleteClick(element?.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </>
                                }
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex justify-center py-5">
                    <p className="text-center text-theme inline-block border-b border-theme">
                      Set User Role Now
                    </p>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </>
      )}

      {/* <AddButton value="Add User" /> */}
      {showEditOrganisationPopup && (
        <EditOrganisationPopup
          organisationData={organisationData}
          setShowEditOrganisationPopup={setShowEditOrganisationPopup}
          companyId={companyId}
        />
      )}
      {showDirectorsPopup && (
        <AddEditDirectors
          directorsData={directorsData}
          companyByIdData={companyByIdData}
          setShowDirectorsPopup={setShowDirectorsPopup}
          getDirectorData={companyByIdData?.resident_directors}
          currentUserId={currentUserId}
          setLoading={setLoading}
          editType={editType}
          companyId={companyId}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmationModel
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleConfirmDelete}
          confirmText="Delete Document"
          isLoading={loading}
          companyId={companyId}
        />
      )}
      {showUserPopup && (
        <AddEditUser
          companyByIdData={companyByIdData}
          setShowUserPopup={setShowUserPopup}
          currentUserId={currentUserId}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default Organisation;
