import React, { useEffect, useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { ArrowDown, Plus } from "../../assets/svg/AllSvg";
import DebounceSearch from "../../commons/DebounceSearch";
import ReactSelect from "react-select";
import Toggles from "../../commons/Toggles";
import CustomTable from "../../commons/CustomTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStaffsList, updateStaffStatus } from "../../redux/staffs/slice";
import RippleLoader from "../../commons/RippleLoader";
import { getAllRoleList } from "../../redux/accounts/slice";
import { forgetPassword } from "../../redux/auth/slice";
import Action from "../../commons/Action";

const StaffAccounts = () => {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("active");
  // const [showConfirmArchivePopup, setShowConfirmArchivePopup] = useState(false);
  const [resetPasswordLoad, setResetPasswordLoad] = useState(false);
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const [showConfirmPasswordPopup, setShowConfirmPasswordPopup] =
    useState(false);
  const [staffData, setStaffData] = useState({});
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [allRoleListArray, setAllRoleListArray] = useState([]);
  const [selectedRole, setSelectedRole] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const { staffsList, staffsListLoading, allRoleList, allRoleLoading } =
    useSelector((store) => ({
      staffsList: store?.staffsData?.getStaffsListStatus?.data,
      staffsListLoading: store?.staffsData?.loading,
      // all RoleL ist
      allRoleList: store?.accountsData?.getAllRoleListStatus?.data,
      allRoleLoading: store?.accountsData?.getAllRoleListStatus?.loading,
    }));

  useEffect(() => {
    dispatch(
      getStaffsList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        status: activeTab === "archived" ? "archvie" : activeTab,
        sort_by: addSort,
        sort_direction: addSortDirection === "asc" ? "asc" : "desc",
        role: selectedRole?.id,
      })
    );
  }, [
    dispatch,
    searchValue,
    pageCount,
    pageLimit,
    activeTab,
    selectedRole,
    addSortDirection,
    addSort,
  ]);

  useEffect(() => {
    dispatch(getAllRoleList());
  }, []);

  useEffect(() => {
    if (allRoleList?.data) {
      let data = allRoleList.data?.map((x) => ({ ...x, label: x.title }));
      setAllRoleListArray(data);
    }
  }, [allRoleList]);

  // Update Status
  const updateStatus = (element) => {
    dispatch(
      updateStaffStatus({
        id: element?.id,
        data: {
          status: element?.status === "inactive" ? "active" : "inactive",
        },
      })
    ).then((res) => {
      if (res.type === "updateStaffStatus/fulfilled") {
        dispatch(
          getStaffsList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            status: activeTab === "archived" ? "archvie" : activeTab,
            sort_by: addSort,
            sort_direction: addSortDirection === "asc" ? "asc" : "desc",
            role: selectedRole?.id,
          })
        );
      }
    });
  };
  // Send Reset Password
  const sendResetPassword = (element) => {
    setResetPasswordLoad(true);
    dispatch(
      forgetPassword({ email: element?.email, role: element?.role })
    ).then((res) => {
      setShowConfirmPasswordPopup(false);
      setResetPasswordLoad(false);
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 table_responsive_text border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          ID
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Name </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "full_name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("full_name");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Role </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "role"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("role");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Mobile </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "phone"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("phone");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Email </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "email"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("email");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 table_responsive_text border-b font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(staffsList?.data?.data || [])?.map((element) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.id}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.full_name}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text capitalize">
              {element?.role}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.phone}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.email}
            </td>
            <td className="px-5 py-3 table_responsive_text">
              <Toggles
                checked={element?.status === "inactive" ? false : true}
                toggleFor={element?.id}
                onChange={(e) => {
                  updateStatus(element);
                }}
              />
            </td>
            <td className="flex px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        navigate("/create-edit-staff-account", {
                          state: { element: element },
                        });
                      }}
                    >
                      View/Edit
                    </li>
                    {/* <li
                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                onClick={() => {
                  setStaffData(element);
                  setShowConfirmArchivePopup(true);
                }}
              >
                {element?.status === "inactive" ? "Active" : "Archive"}
              </li> */}
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setStaffData(element);
                        setShowConfirmPasswordPopup(true);
                      }}
                    >
                      Reset Password
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="space-y-5">
        {/* Page Header */}
        <div className="flex justify-between items-start">
          <div>
            <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
              Staff Account
            </h6>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbActive={"Staff Account"}
              breadCrumbParent2={"Accounts"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <button
            className="btn-theme flex items-center uppercase"
            onClick={() => {
              navigate("/create-edit-staff-account");
            }}
          >
            <Plus className="mr-2" /> Add Staff
          </button>
        </div>
        {/* Active | Archive Tab */}
        <div className="flex justify-end">
          {["active", "archived"].map((tab, index) => {
            return (
              <button
                key={tab}
                className={`transition uppercase duration-300 ${
                  index === 0 ? "!rounded-l !rounded-r-none" : ""
                } ${index === 1 ? "!rounded-r !rounded-l-none" : ""} ${
                  activeTab === tab ? "btn-theme" : "btn-light_primary"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </div>
        {/* Search | Filter of Staff */}
        <div className="sm:flex items-center sm:space-x-3 sm:space-y-0 space-y-2 ">
          <ReactSelect
            className={"w-full sm:max-w-xs responsive_base_text text-black p-0"}
            id="role"
            placeholder="Select Role"
            options={allRoleListArray}
            isLoading={allRoleLoading}
            getOptionValue={(selectedRole) => selectedRole.value}
            value={selectedRole}
            onChange={(e) => {
              setSelectedRole(e);
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
          <DebounceSearch
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search..."}
          />
        </div>
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={staffsList?.data}
            overflowVisibleLg={true}
            // loader
            loading={staffsListLoading}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={staffsList?.data?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>
      {/* {showConfirmArchivePopup && (
        <div className="model">
          <div className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-sm min-h-40 py-8 px-6 mx-auto relative">
            <h2 className="text-2xl font-ebGaramond mb-4">
              Confirm {staffData?.status === "inactive" ? "Active" : "Archive"}?
            </h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setShowConfirmArchivePopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                onClick={() => {
                  updateStatus(staffData);
                  setShowConfirmArchivePopup(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )} */}
      {showConfirmPasswordPopup && (
        <div
          className="model"
          onClick={() => {
            setShowConfirmPasswordPopup(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-md min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="text-2xl font-ebGaramond text-center max-w-60 mb-4">
              Confirm Send Password Reset Link?
            </h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setShowConfirmPasswordPopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                disabled={resetPasswordLoad}
                onClick={() => {
                  sendResetPassword(staffData);
                }}
              >
                {resetPasswordLoad ? "Loading......" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StaffAccounts;
