import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import layoutContext from "./layoutContext";
import { Logo } from "../assets/image";
// import { AdminContext } from ".";
import { OutVector } from "../assets/svg/AllSvg";
import SidebarDropDown from "../commons/SidebarDropDown";
import { useSelector } from "react-redux";

const Sidebar = () => {
  // hooks
  const { isAsideOpen } = useContext(layoutContext);

  const navigate = useNavigate();
  const location = useLocation();

  // profile
  const { getProfileData } = useSelector((store) => ({
    getProfileData: store?.authData?.adminGetProfileDataStatus?.data,
  }));

  //url, permissions, icons, title, badge
  // const Menus = [
  //   {
  //     title: "Dashboard",
  //     src: <Vector width={20} />,
  //     openBar: "",
  //   },
  //   {
  //     title: "Sales",
  //     src: <ShoppingCart width={20} />,
  //     openBar: "sales",
  //   },
  //   {
  //     title: "Service Request",
  //     src: <Work width={20} />,
  //     openBar: "service_request",
  //   },
  //   {
  //     title: "Tickets",
  //     src: <MarkUnreadMailbox width={20} />,
  //     openBar: "tickets",
  //   },
  //   {
  //     title: "Customers",
  //     src: <User width={20} />,
  //     openBar: "customers",
  //   },
  //   {
  //     title: "Companies",
  //     src: <CompaniesIcon width={20} />,
  //     openBar: "companies",
  //   },
  //   {
  //     title: "Broadcast",
  //     src: <Campaign width={20} />,
  //     openBar: "broadcast",
  //   },
  //   {
  //     title: "Activity Log",
  //     src: <ActivityLog width={20} />,
  //     openBar: "activity-log",
  //   },
  // ];

  // Log Out Clear
  const logOutClear = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <aside
        className={`${
          isAsideOpen
            ? "w-[16rem] bg-white bottom-0 sm:top-auto top-16"
            : "md:w-[5rem] w-0 top-0"
        } fixed z-[99] md:relative flex flex-col justify-between md:overflow-visible overflow-hidden transition-all border-r h-[calc(100vh-4rem)]`}
      >
        <div className="h-full">
          <div className="flex items-center justify-center px-5 py-2.5 gap-x-4 border-b border-gray-200 relative">
            <div
              className={`origin-left font-medium text-xl duration-250 overflow-hidden flex justify-center items-center cursor-pointer ${
                isAsideOpen ? "w-[12rem] h-16" : "w-16 h-11"
              }`}
              onClick={() => navigate("/")}
            >
              <img className="h-full object-cover" src={Logo} alt="logo" />
            </div>
          </div>
          <ul
            className={`p-4 h-[calc(100vh-12.9rem)] ${
              isAsideOpen ? "overflow-y-auto" : "overflow-visible"
            }  `}
          >
            {getProfileData?.permission_list?.map((menu, index) => (
              <>
                {menu?.subMenu?.length ? (
                  <SidebarDropDown isAsideOpen={isAsideOpen} menu={menu} />
                ) : (
                  <li key={index} className={`group aside-links tooltipBlock`}>
                    <button
                      className={`flex items-center relative z-[99999] ${
                        !isAsideOpen
                          ? "px-2 hover:translate-x-0 hover:bg-light_secondary_shades my-1 justify-center"
                          : "hover:translate-x-1 px-4 "
                      }  py-2 gap-x-4 h-auto  hover:text-black w-full rounded-lg transition duration-200  ${
                        location?.pathname ===
                          `/${menu.path === "dashboard" ? "" : menu?.path}` &&
                        `bg-light_secondary_shades`
                      }`}
                      onClick={() => {
                        navigate(
                          `${menu?.path === "dashboard" ? "/" : menu?.path}`
                        );
                      }}
                    >
                      <div className="max-w-5 max-h-5 w-full">
                        <img
                          src={menu?.icon_path ?? Logo}
                          className="w-full h-full object-contain"
                          alt="icon"
                        />
                      </div>
                      <p
                        className={`origin-left font-medium duration-250 flex items-center text-left  ${
                          !isAsideOpen && "hidden"
                        }`}
                      >
                        {menu?.title}
                      </p>
                      {!isAsideOpen && (
                        <div
                          id="tooltip"
                          className="right absolute top-[20%] left-[55px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
                        >
                          <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
                          <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px]">
                            {menu?.title}
                          </div>
                        </div>
                      )}
                    </button>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
        {/* Log Out */}
        <div className="p-4 flex items-center border-t">
          <button
            className={`flex items-center relative z-[99999] ${
              !isAsideOpen
                ? "px-2 hover:translate-x-0 hover:bg-light_secondary_shades my-1 justify-center"
                : "hover:translate-x-1 px-4 "
            } gap-x-4 h-auto  hover:text-black w-full rounded-lg transition duration-200`}
            onClick={() => logOutClear()}
          >
            <OutVector />
            <p
              className={`origin-left font-medium duration-250 flex  items-center  ${
                !isAsideOpen && "hidden"
              }   `}
            >
              Log Out
            </p>
            {!isAsideOpen && (
              <div
                id="tooltip"
                className="right absolute top-[20%] left-[55px] text-xs whitespace-nowrap font-semibold -z-[100] hidden"
              >
                <div className="absolute w-0 h-0 border-transparent border-solid tooltip-arrow border-r-transparent" />
                <div className="tooltip-label py-1 px-2 text-white bg-black rounded-[4px] text-center max-w-[180px]">
                  <OutVector />
                </div>
              </div>
            )}
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
