import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, SelectBox } from "../../components/micro";
import Toggles from "../../commons/Toggles";
import Breadcrumb from "../../commons/Breadcrumb";
import DebounceSearch from "../../commons/DebounceSearch";
import { ArrowDown, FileDownload, Plus } from "../../assets/svg/AllSvg";
import CustomTable from "../../commons/CustomTable";
import Action from "../../commons/Action";
import { useDispatch, useSelector } from "react-redux";
import { companiesList, employmentSupport } from "../../redux/companies/slice";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ConfirmationModel from "../../commons/ConfirmationModel";

const Companies = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [selectedTagStatus, setSelectedTagStatus] = useState();
  const [selectedKyc, setSelectedKyc] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [companyData, setCompanyData] = useState({});
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const [showConfirmArchivePopup, setShowConfirmArchivePopup] = useState(false);
  const [updateActiveArchiveLoad, setUpdateActiveArchiveLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selector
  const { companiesListData, loading } = useSelector((store) => ({
    companiesListData: store?.companiesData?.companiesListStatus?.data?.data,
    loading: store?.companiesData?.loading,
  }));

  useEffect(() => {
    dispatch(
      companiesList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        kyc: selectedKyc?.value ?? "",
        tag_status:
          selectedTagStatus?.value === "tagged"
            ? 1
            : selectedTagStatus?.value === "untagged"
            ? 0
            : "",
        status: activeTab === "archived" ? "archive" : activeTab,
        sort_by: addSort,
        sort_direction: addSortDirection === "asc" ? "asc" : "desc",
      })
    );
  }, [
    dispatch,
    searchValue,
    pageCount,
    pageLimit,
    activeTab,
    selectedKyc,
    selectedTagStatus,
    addSort,
    addSortDirection,
  ]);

  // Active and Archived Tab
  const tabs = [
    {
      label: "active",
    },
    { label: "archived" },
  ];

  // Tag Status Dropdown
  const tagStatusDropdown = [
    { value: "tagged", label: "Tagged" },
    { value: "untagged", label: "Untagged" },
  ];

  //   KYC Dropdown
  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];

  // Update Status
  const updateStatus = (element) => {
    dispatch(
      employmentSupport({
        id: element?.id,
        data: {
          is_active: element.is_active === 1 ? 0 : 1,
          status: element.is_active === 0 ? "active" : "inactive",
        },
      })
    ).then((res) => {
      if (res.type === "employmentSupport/fulfilled") {
        dispatch(
          companiesList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            kyc: selectedKyc?.value ?? "",
            tag_status:
              selectedTagStatus?.value === "tagged"
                ? 1
                : selectedTagStatus?.value === "untagged"
                ? 0
                : "",
            status: activeTab === "archived" ? "archive" : activeTab,
            sort_by: addSort,
            sort_direction: addSortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
    });
  };
  // Status Active & Archive
  const updateActiveArchiveStatus = (element) => {
    setUpdateActiveArchiveLoad(true);
    dispatch(
      employmentSupport({
        id: element?.id,
        data: {
          is_active: element.status === "archive" ? 1 : 0,
          status: element.status === "archive" ? "active" : "archive",
        },
      })
    ).then((res) => {
      setUpdateActiveArchiveLoad(false);
      setShowConfirmArchivePopup(false);
      if (res.type === "employmentSupport/fulfilled") {
        dispatch(
          companiesList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            kyc: selectedKyc?.value ?? "",
            tag_status:
              selectedTagStatus?.value === "tagged"
                ? 1
                : selectedTagStatus?.value === "untagged"
                ? 0
                : "",
            status: activeTab === "archived" ? "archive" : activeTab,
            sort_by: addSort,
            sort_direction: addSortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-primary_shades_light whitespace-nowrap"
        >
          <div className="flex items-center">
            <p> ID </p>

            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "company_id"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("company_id");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p>Company Name</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("name");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Email </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "email"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("email");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Mobile </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "phone"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("phone");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p>KYC</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "kyc_status"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("kyc_status");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Tag Status</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "tagged"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("tagged");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(companiesListData?.data || [])?.map((element) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">{element?.company_id ?? "-"}</td>
            <td className="px-5 py-3 truncate capitalize">
              {element?.name ?? "-"}
            </td>
            <td className="px-5 py-3 truncate">{element?.user_email ?? "-"}</td>
            <td className="px-5 py-3 truncate">{element?.user_phone ?? "-"}</td>
            <td className="px-5 py-3 truncate">
              {
                <div className="flex justify-start">
                  <Badge
                    className={`capitalize ${
                      element?.kyc_status === "low_risk"
                        ? "border-green-700 text-green-700"
                        : element?.kyc_status === "mid_risk"
                        ? "border-orange-700 text-orange-700"
                        : element?.kyc_status === "high_risk"
                        ? "border-red-700 text-red-700"
                        : element?.kyc_status === "pending"
                        ? "text-black bg-secondary_light_shades"
                        : ""
                    }`}
                    value={
                      element?.kyc_status === "low_risk"
                        ? "Low Risk"
                        : element?.kyc_status === "mid_risk"
                        ? "Mid Risk"
                        : element?.kyc_status === "high_risk"
                        ? "High Risk"
                        : element?.kyc_status === "pending"
                        ? "Pending"
                        : ""
                    }
                  />
                </div>
              }
            </td>
            <td className="px-5 py-3">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`text-white capitalize ${
                      element?.tagged === 1
                        ? "border-green-700 bg-green-700"
                        : "border-red-700 bg-red-700"
                    }`}
                    value={element?.tagged === 1 ? "Tagged" : "Untagged"}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3">
              <Toggles
                toggleFor={element?.id}
                checked={element?.is_active === 1}
                onChange={() => {
                  updateStatus(element);
                }}
              />
            </td>
            <td className="flex justify-start px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/view-company", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setCompanyData(element);
                        setShowConfirmArchivePopup(true);
                      }}
                    >
                      {element?.status === "archive" ? "Active" : "Archive"}
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="space-y-5">
        {/* Page Header */}
        <div className="flex justify-between items-start">
          <div>
            <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
              Companies
            </h6>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbActive={"Companies"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <button
            className="btn-theme flex items-center uppercase"
            onClick={() => {
              navigate("/create-edit-company");
            }}
          >
            <Plus className="mr-2" /> Add company
          </button>
        </div>
        {/* Active | Archive Filter Tab */}
        <div className="flex justify-end">
          {tabs.map((tab, index) => {
            return (
              <button
                key={tab.label}
                className={`transition uppercase duration-300 ${
                  index === 0 ? "!rounded-l !rounded-r-none" : ""
                }
            ${index === tabs.length - 1 ? "!rounded-r !rounded-l-none" : ""} ${
                  activeTab === tab.label ? "btn-theme" : "btn-light_primary"
                }`}
                onClick={() => setActiveTab(tab.label)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {/* Search | Filter of List */}
        <div className="flex lg:flex-row flex-col lg:justify-between lg:items-start items-end lg:space-y-0 space-y-2">
          <div className="sm:flex items-center sm:space-x-3 space-x-0 w-full sm:space-y-0 space-y-2">
            <DebounceSearch
              search={searchValue}
              handleChange={setSearchValue}
              placeholder={"Search..."}
            />
            <SelectBox
              className={
                "w-full sm:max-w-xs text-black responsive_base_text whitespace-nowrap p-0"
              }
              id="tagStatusDropdown"
              placeholder="Select Tag Status"
              paddingY={"2px"}
              options={tagStatusDropdown}
              isLoading={false}
              getOptionValue={(selectedTagStatus) => selectedTagStatus.value}
              value={selectedTagStatus}
              onChange={(e) => {
                setSelectedTagStatus(e);
              }}
            />
            <SelectBox
              className={
                "w-full sm:max-w-xs text-black responsive_base_text whitespace-nowrap p-0"
              }
              id="kyc"
              placeholder="Select KYC"
              options={kycDropdown}
              paddingY={"2px"}
              isLoading={false}
              getOptionValue={(selectedKyc) => selectedKyc.value}
              value={selectedKyc}
              onChange={(e) => {
                setSelectedKyc(e);
              }}
            />
          </div>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn-outline flex items-center whitespace-nowrap flex-nowrap uppercase"
            table="table-to-xls"
            filename={`Companies`}
            sheet={`Companies`}
            buttonText={
              <>
                <FileDownload width="14" height="17" className="mr-3" />
                Export CSV
              </>
            }
          />
        </div>
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={companiesListData}
            overflowVisibleLg={true}
            // loader
            loading={loading}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={companiesListData?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>
      {showConfirmArchivePopup && (
        <ConfirmationModel
          isLoading={updateActiveArchiveLoad}
          onClose={() => {
            setShowConfirmArchivePopup(false);
          }}
          onConfirm={() => {
            updateActiveArchiveStatus(companyData);
          }}
          confirmText={companyData?.status === "archive" ? "Active" : "Archive"}
        />
      )}
      <table id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Company Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">KYS</th>
            <th scope="col">Tag Status</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {companiesListData?.data?.map((element) => {
            return (
              <tr>
                <td>{element?.company_id ?? "-"}</td>
                <td>{element?.name ?? "-"}</td>
                <td>{element?.user_email ?? "-"}</td>
                <td>{element?.user_phone ?? "-"}</td>
                <td>
                  {element?.kyc_status === "low_risk"
                    ? "Low Risk"
                    : element?.kyc_status === "mid_risk"
                    ? "Mid Risk"
                    : element?.kyc_status === "high_risk"
                    ? "High Risk"
                    : element?.kyc_status === "pending"
                    ? "Pending"
                    : ""}
                </td>
                <td>{element?.tagged === 1 ? "Tagged" : "Untagged"}</td>
                <td>{element?.status === "active" ? "Active" : "Inactive"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Companies;
